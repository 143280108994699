<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="后巷屋订单id,外键{zb_order_laneway_houses.id}" prop="orderLanewayHousesId" >
        <a-input v-model="form.orderLanewayHousesId" placeholder="请输入后巷屋订单id,外键{zb_order_laneway_houses.id}" />
      </a-form-model-item>
      <a-form-model-item label="服务名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入服务名称" />
      </a-form-model-item>
      <a-form-model-item label="服务类型" prop="type" >
        <a-select placeholder="请选择服务类型" option-filter-prop="children" style="width: 100%" v-model="form.type">
          <a-select-option value="0">方案</a-select-option>
          <a-select-option value="1">施工 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="服务价格" prop="money" >
        <a-input v-model="form.money" placeholder="请输入服务价格" />
      </a-form-model-item>
      <a-form-model-item label="顺序" prop="sort" >
        <a-input v-model="form.sort" placeholder="请输入顺序" />
      </a-form-model-item>
      <a-form-model-item label="服务状态" prop="status" >
        <a-select placeholder="请选择服务类型" option-filter-prop="children" style="width: 100%" v-model="form.status">
          <a-select-option value="0">未开始</a-select-option>
          <a-select-option value="1">待开始/待上传 </a-select-option>
          <a-select-option value="2">待支付 </a-select-option>
          <a-select-option value="3">已支付 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="支付状态,0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付" prop="payStatus" >
        <a-select placeholder="请选择服务类型" option-filter-prop="children" style="width: 100%" v-model="form.status">
          <a-select-option value="0">未开始</a-select-option>
          <a-select-option value="1">待支付 </a-select-option>
          <a-select-option value="2">线下支付待审核 </a-select-option>
          <a-select-option value="3">线下支付 </a-select-option>
          <a-select-option value="4">已支付 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="支付时间" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="线下支付截图,多个使用逗号隔开" prop="payScreenshot" >
        <a-input v-model="form.payScreenshot" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="支付金额" prop="payMoney" >
        <a-input v-model="form.payMoney" placeholder="请输入支付金额" />
      </a-form-model-item>
      <a-form-model-item label="定金抵扣金额" prop="deductionMoney" >
        <a-input v-model="form.deductionMoney" placeholder="请输入定金抵扣金额" />
      </a-form-model-item>
      <a-form-model-item label="服务者上传文件" prop="fileIds" >
        <a-input v-model="form.fileIds" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLanewayHousesServe, addLanewayHousesServe, updateLanewayHousesServe } from '@/api/order/lanewayHousesServe'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        orderLanewayHousesId: null,

        name: null,

        type: null,

        money: null,

        sort: null,

        status: 0,

        payStatus: 0,

        payTime: null,

        payScreenshot: null,

        payMoney: null,

        deductionMoney: null,

        fileIds: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        orderLanewayHousesId: [
          { required: true, message: '后巷屋订单id,外键{zb_order_laneway_houses.id}不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '服务名称不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '服务类型,0-方案,1-施工不能为空', trigger: 'change' }
        ],
        money: [
          { required: true, message: '服务价格不能为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '顺序不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '服务状态,0-未开始,1-待开始/待上传,2-待支付,3-已支付不能为空', trigger: 'blur' }
        ],
        payStatus: [
          { required: true, message: '支付状态,0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付不能为空', trigger: 'blur' }
        ],
        payScreenshot: [
          { required: true, message: '线下支付截图,多个使用逗号隔开不能为空', trigger: 'blur' }
        ],
        payMoney: [
          { required: true, message: '支付金额不能为空', trigger: 'blur' }
        ],
        deductionMoney: [
          { required: true, message: '定金抵扣金额不能为空', trigger: 'blur' }
        ],
        fileIds: [
          { required: true, message: '服务者上传文件,外键{zb_file.id},多个使用逗号隔开不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        orderLanewayHousesId: null,
        name: null,
        type: null,
        money: null,
        sort: null,
        status: 0,
        payStatus: 0,
        payTime: null,
        payScreenshot: null,
        payMoney: null,
        deductionMoney: null,
        fileIds: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLanewayHousesServe({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLanewayHousesServe(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addLanewayHousesServe(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--            <a-col :md="8" :sm="24">-->
            <!--              <a-form-item label="后巷屋订单id,外键{zb_order_laneway_houses.id}" prop="orderLanewayHousesId">-->
            <!--                <a-input v-model="queryParam.orderLanewayHousesId" placeholder="请输入后巷屋订单id,外键{zb_order_laneway_houses.id}" allow-clear/>-->
            <!--              </a-form-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="服务名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入服务名称" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="服务价格" prop="money">-->
              <!--                  <a-input v-model="queryParam.money" placeholder="请输入服务价格" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="顺序" prop="sort">-->
              <!--                  <a-input v-model="queryParam.sort" placeholder="请输入顺序" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="支付时间" prop="payTime">-->
              <!--                  <a-date-picker style="width: 100%" v-model="queryParam.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="支付金额" prop="payMoney">-->
              <!--                  <a-input v-model="queryParam.payMoney" placeholder="请输入支付金额" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
              <!--              <a-col :md="8" :sm="24">-->
              <!--                <a-form-item label="定金抵扣金额" prop="deductionMoney">-->
              <!--                  <a-input v-model="queryParam.deductionMoney" placeholder="请输入定金抵扣金额" allow-clear/>-->
              <!--                </a-form-item>-->
              <!--              </a-col>-->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['order:lanewayHousesServe:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['order:lanewayHousesServe:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['order:lanewayHousesServe:remove']"
        >
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:lanewayHousesServe:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:lanewayHousesServe:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:lanewayHousesServe:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['order:lanewayHousesServe:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['order:lanewayHousesServe:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageLanewayHousesServe, listLanewayHousesServe, delLanewayHousesServe } from '@/api/order/lanewayHousesServe'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'LanewayHousesServe',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        orderLanewayHousesId: null,
        name: null,
        type: null,
        money: null,
        sort: null,
        status: null,
        payStatus: null,
        payTime: null,
        payScreenshot: null,
        payMoney: null,
        deductionMoney: null,
        fileIds: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '后巷屋订单id',
          dataIndex: 'orderLanewayHousesId',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '服务名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '服务类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (text) {
            if (text == 0) {
              return '方案'
            } else {
              return '施工'
            }
          },
        },
        {
          title: '服务价格',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '顺序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center',
        },
        {
          //0-未开始,1-待开始/待上传,2-待支付,3-已支付
          title: '服务状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
        },
        {
          //  ,0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付
          title: '支付状态',
          dataIndex: 'payStatus',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '线下支付截图,多个使用逗号隔开',
          dataIndex: 'payScreenshot',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '支付金额',
          dataIndex: 'payMoney',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '定金抵扣金额',
          dataIndex: 'deductionMoney',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '服务者上传文件,外键{zb_file.id},多个使用逗号隔开',
          dataIndex: 'fileIds',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询后巷屋订单服务列表 */
    getList() {
      this.loading = true
      pageLanewayHousesServe(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        orderLanewayHousesId: undefined,
        name: undefined,
        type: undefined,
        money: undefined,
        sort: undefined,
        status: undefined,
        payStatus: undefined,
        payTime: undefined,
        payScreenshot: undefined,
        payMoney: undefined,
        deductionMoney: undefined,
        fileIds: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delLanewayHousesServe(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'order/orderLanewayHousesServe/export',
            {
              ...that.queryParam,
            },
            `后巷屋订单服务_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
  },
}
</script>

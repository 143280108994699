import request from '@/utils/request'


// 查询后巷屋订单服务列表
export function listLanewayHousesServe(query) {
  return request({
    url: '/order/orderLanewayHousesServe/list',
    method: 'get',
    params: query
  })
}

// 查询后巷屋订单服务分页
export function pageLanewayHousesServe(query) {
  return request({
    url: '/order/orderLanewayHousesServe/page',
    method: 'get',
    params: query
  })
}

// 查询后巷屋订单服务详细
export function getLanewayHousesServe(data) {
  return request({
    url: '/order/orderLanewayHousesServe/detail',
    method: 'get',
    params: data
  })
}

// 新增后巷屋订单服务
export function addLanewayHousesServe(data) {
  return request({
    url: '/order/orderLanewayHousesServe/add',
    method: 'post',
    data: data
  })
}

// 修改后巷屋订单服务
export function updateLanewayHousesServe(data) {
  return request({
    url: '/order/orderLanewayHousesServe/edit',
    method: 'post',
    data: data
  })
}

// 删除后巷屋订单服务
export function delLanewayHousesServe(data) {
  return request({
    url: '/order/orderLanewayHousesServe/delete',
    method: 'post',
    data: data
  })
}
